.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 100%;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  height: 20vh;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header button { 
  margin-bottom: 3vh;
}

.App-link {
  color: #61dafb;
}

.App-body {
  font-size: calc(10px + 2vmin);
  padding-bottom: 2px;
}

.stack {
  display: flex;
  flex-direction: column;
  flex-wrap: 'nowrap';
  gap: 5px;
  width: auto;
}

.stackHoz {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.center {
  align-items: center;
  justify-content: center;
}

.stretch {
  align-items: stretch;
}

.spaceEven {
  justify-content: space-evenly;
}

.spaceAround {
  justify-content: space-around;
}

.spaceBetween {
  justify-content: space-between;
}

.Slot {
  width: 15vw;
  height: 15vh;
  line-height: 15vh;
  font-size: 2vh;
  overflow: hidden;
}

.Slot span {
  text-overflow: ellipsis;
}

.Game-ID {
  position: absolute;
  top: 15vh;
  height: 3vh;
  color: #fff;
  right: 49vw;
  font-size: calc(12px + 0.4vmin)
}

@media only screen and (max-width: 400px) {
  .App-logo {
    height: 50%;
  }
  .App-header button {
    margin-bottom: 0;
  }
  .Game-ID {
    font-size: 12px;
    right: 40vw;
  }
  .Slot {
    font-size: 1.3vh;
  }
}